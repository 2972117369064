























import { AttendeeDataObject } from "@/types/interfaces";
import AttendeeCard from "@/components/shared/AttendeeCard.vue";

export default {
    components: {
        AttendeeCard
    },

    props: {
        headerLabel: {
            type: String,
            default: "Featured Attendees",
            required: false
        },
        attendees: {
            type: Array as () => AttendeeDataObject,
            default: [],
            required: true
        },
        isSearchButtonActive: {
            type: Boolean,
            default: false
        },
        headerClasses: {
            type: String,
            default: ""
        }
    }
};
